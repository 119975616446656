import React from 'react';
import { BannerMonthlyPromo } from 'views/offer/banner-monthly-promo';
import { PATHS } from 'constants/paths';
import { treatmentsHubs } from 'constants/treatment-hubs';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { graphql, useStaticQuery } from 'gatsby';
import { TreatmentsList } from '../components/treatments-list';
import styled from 'styled-components';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1200px;
`;

export const useBotoxContent = () => {

  const images = useStaticQuery(graphql`
      query {
      one: file(relativePath: { eq: "ig-zmarszczki-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      two: file(relativePath: { eq: "ig-zmarszczki-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      three: file(relativePath: { eq: "ig-zmarszczki-3.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      five: file(relativePath: { eq: "gs-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return {
    title: 'Zabiegi z toksyną botulinową',
    sections: [
      {
        type: 'section',
        subtitle: 'Czym jest toksyna botulinowa?',
        nodes: [
          {
            type: 'paragraph',
            content:
              'Toksyna botulinowa, znana również jako botox, jest substancją stosowaną w medycynie estetycznej do różnorodnych zabiegów poprawiających wygląd i komfort życia. Użycie tego związku pozwala na efektywne niwelowanie zmarszczek mimicznych, a także jest wykorzystywane w leczeniu takich problemów jak nadpotliwość czy bruksizm. Toksyna ta, produkowana w kontrolowanych warunkach, jest bezpieczna w stosowaniu i oferuje <b>zauważalne efekty już po pierwszym zabiegu</b>.',
          },
        ],
      },
      {
        type: 'section',
        subtitle: 'Jak działa botoks?',
        nodes: [
          {
            type: 'paragraph',
            content:
              '<b>Działanie botoxu</b> polega na blokowaniu sygnałów nerwowych, co prowadzi do rozluźnienia mięśni. W rezultacie, zmarszczki stają się mniej widoczne, a skóra wygląda na odmłodzoną. Efekty zabiegu z użyciem toksyny botulinowej są tymczasowe, ale mogą utrzymywać się od kilku do kilkunastu miesięcy, zależnie od indywidualnych reakcji organizmu oraz miejsca podania botoksu.',
          },
          {
            type: 'paragraph',
            content:
              'Wiele osób decyduje się na ponowne zabiegi z użyciem botoxu, aby dłużej cieszyć się gładką i młodzieńczą cerą. Regularne powtarzanie zabiegu z użyciem botoksu pozwala na utrzymanie efektów i kontrolę nad procesem starzenia się skóry w okolicach oczu, ust czy na czole. ',
          },
          { type: 'node', content: <SImageWrapper>
              <Image
                src={images.five.childImageSharp}
                alt="botoks warszawa gummy smile uśmiech dziąsłowy ideale medycyna estetyczna wola"
              />
              <Image
                src={images.one.childImageSharp}
                alt="usuwanie zmarszczek zmarszczki poprzeczne czoła botox toksyna botulinowa ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.two.childImageSharp}
                alt="usuwanie zmarszczek zmarszczki poprzeczne czoła botox toksyna botulinowa ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.three.childImageSharp}
                alt="usuwanie zmarszczek lwia zmarszczka botox toksyna botulinowa ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
          },
        ],
      },
      {
        type: 'section',
        subtitle: 'Zabiegi z wykorzystaniem toksyny botulinowej w IDEALE',
        nodes: [
          {
            type: 'paragraph',
            content:
              'W gabinecie medycyny estetycznej IDEALE w Warszawie oferujemy szeroki zakres zabiegów z użyciem toksyny botulinowej. Nasi pacjenci mogą skorzystać z następującego zastosowania botoksu:',
          },
          {
            type: 'node',
            content: (
              <TreatmentsList offers={treatmentsHubs.botox.treatments} />
            ),
          },
          { type: 'node', content: <BannerMonthlyPromo /> },
        ],
      },
      {
        type: 'section',
        subtitle: 'Botox Warszawa tylko w IDEALE',
        nodes: [
          {
            type: 'paragraph',
            content:
              '<b>IDEALE Medycyna Estetyczna to gabinet stworzony przez kobietę dla kobiet</b>. Dążymy do tego aby nasi Pacjenci poczuli się piękni i dostrzegli w sobie wyjątkowe, unikatowe cechy. Podkreślamy to co interesujące. Słuchamy i doradzamy. Nasz doświadczony lekarz medycyny estetycznej ułoży dla Ciebie plan, indywidualnie dopasuje zabiegi mezoterapii igłowej, doradzi w temacie pielęgnacji skóry, dzięki czemu wyjdziesz od nas piękna, naturalna i promienna, a Twoja skóra będzie wyglądać na młodszą i bardziej wypoczętą. Nasza klinika mieści się w Warszawie na Woli, tuż przy stacji metra Młynów zapewniając łatwy dojazd naszym pacjentom. Wokół kliniki znajduje się również wiele miejsc parkingowych.',
          },
          {
            type: 'paragraph',
            content: `W IDEALE skupiamy się na dostarczaniu skutecznych i bezpiecznych <a href="${PATHS.OFFER}" >zabiegów medycyny estetycznej</a>, które czerpiemy prosto z międzynarodowych kongresów i szkoleń. Dążymy do zapewnienia najwyższej jakości usług, a nasz zespół stale poszerza swoją wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom. Cena wybranego zabiegu jest dostępna w naszym cenniku.`,
          },
          {
            type: 'paragraph',
            content: `Razem z nami odkryjesz potencjał swojej urody i swoją unikatowość. Poczujesz się zadbana i zrelaksowana. Zaufaj naszemu doświadczeniu, abyś mogła cieszyć się piękną, zadbaną i młodą skórą.`,
          },
        ],
      },
    ],
  };
};

import React from 'react';
import { TreatmentsSubpage } from 'views/treatments-subpage';
import { useBotoxContent } from 'views/treatments-subpage/hooks/botox';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { treatmentsHubs } from 'constants/treatment-hubs';
import { Layout } from 'layouts';

const BotoxPage = () => {
  const botoxContent = useBotoxContent();

  return (
    <Layout>
      <TreatmentsSubpage
        breadcrumbLinks={treatmentsHubs.botox.links}
        {...botoxContent}
      />
    </Layout>
  );
};

export default BotoxPage;

export const Head = () => <SEO tags={TAGS.zabiegiBotoks} />;
